import { Box, BoxProps } from '@wh-components/core/Box/Box'
import Favorite from '@wh-components/icons/Favorite'
import React, { FunctionComponent } from 'react'
import { Text } from '@wh-components/core/Text/Text'

/**
 * Range definition for overallScore bounds.
 */
const trustInputRangeStart = 0
const trustInputRangeEnd = 1

/**
 * Range definition for star ouput range mapping.
 */
const starOutputRangeStart = 0
const starOutputRangeEnd = 5

export const PrivateProfilePayLiveryRatingsBadge: FunctionComponent<{ overallScore: number } & BoxProps> = ({ overallScore, ...props }) => {
    const starScore = getTrustStars(overallScore, trustInputRangeStart, trustInputRangeEnd, starOutputRangeStart, starOutputRangeEnd)
    const starScoreWithStep = roundStarsScoreToStep(starScore, 0.1)
    return (
        <Box
            testId="bap-paylivery-trust-badge"
            backgroundColor="palette.lavender"
            borderRadius="xl"
            paddingVertical="0.0625em"
            paddingHorizontal="0.75em"
            marginTop="s"
            fontSize="xs"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="semantic.foreground.white"
            {...props}
        >
            <Favorite color="semantic.foreground.white" marginRight="xs" />
            <Text as="span" lineHeight="1">
                <Text as="span" fontWeight="bold" testId="bap-paylivery-trust-badge-star-score">
                    {starScoreWithStep}
                </Text>{' '}
                / 5
            </Text>
        </Box>
    )
}

const roundStarsScoreToStep = (value: number, step: number) => {
    const inv = 1.0 / step
    return Math.round(value * inv) / inv
}

const getTrustStars = (
    overallScore: number,
    trustRangeStart: number,
    trustRangeEnd: number,
    trustStarsStart: number,
    trustStarsEnd: number,
) => ((overallScore - trustRangeStart) * (trustStarsEnd - trustStarsStart)) / (trustRangeEnd - trustRangeStart) + trustStarsStart
