import React, { FunctionComponent, useEffect, useState } from 'react'
import { SearchAgentUIState } from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentButton'
import { Box } from '@wh-components/core/Box/Box'
import styled, { css } from 'styled-components'
import wh_pattern from '@wh/common/chapter/static_hashed/img/myprofile/wh-pattern.svg'
import { Avatar } from '@wh-components/core/Avatar/Avatar'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Text } from '@wh-components/core/Text/Text'
import PinIcon from '@wh-components/icons/Pin'
import dayjs from 'dayjs'
import { ConfirmModal } from '@wh/common/chapter/components/Modals/ConfirmModal/ConfirmModal'
import { ContextLink, findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { CreateSearchAgentModal } from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentModal'
import { Button, IconButton } from '@wh-components/core/Button/Button'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { OPEN_FOLLOW_USER_MODAL_STORAGE_KEY } from '@wh/common/chapter/lib/localStorage'
import { redirectToLoginPage } from '@wh/common/chapter/lib/routing/bbxRouter'
import { PrivateProfilePayLiveryRatingsBadge } from '@bbx/search-journey/common/components/PayliveryTrustSignals/PrivateProfilePayliveryRatingsBadge'
import { PrivateProfilePayLiveryRatingsText } from '@bbx/search-journey/common/components/PayliveryTrustSignals/PrivateProfilePayLiveryRatingsText'
import { PayliveryBadge } from '@wh/common/chapter/components/PayliveryBadge/PayliveryBadge'
import { FollowerProfile, SellerProfile } from '@bbx/search-journey/common/SellerProfile'
import { getFollowerProfile, unfollowUserWithLink } from '@bbx/search-journey/common/api/sellerProfileApiClient'
import CheckMarkIcon from '@wh-components/icons/CheckThick'
import { ShareButton } from '@wh/common/chapter/components/ShareButton'
import ShareIcon from '@wh-components/icons/Share'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TrustSignals } from '@bbx/search-journey/common/TrustProfile'
import { getSearchAgentOptions } from '@bbx/search-journey/common/api/searchAgentApiClient'
import { ReportUserProfileButton } from './ReportUserProfileButton'

export const SellerProfileContainer: FunctionComponent<{
    sellerProfile: SellerProfile
    sellerProfileLoginId: string
    verticalId: number
    contextLinkList: ContextLink[]
    initialFollowerProfile: FollowerProfile | undefined
    shouldShowPayliveryRatings?: boolean
    taggingData: TaggingData
    trustSignals?: TrustSignals
    searchAgentOptionsLink?: ContextLink | undefined
}> = ({
    sellerProfileLoginId,
    verticalId,
    sellerProfile,
    initialFollowerProfile,
    contextLinkList,
    shouldShowPayliveryRatings = false,
    taggingData,
    trustSignals,
    searchAgentOptionsLink,
}) => {
    const [unfollowModalOpen, setUnfollowModalOpen] = useState<boolean>(false)
    const [searchAgentUIState, setSearchAgentUIState] = useState<SearchAgentUIState>({ state: 'idle' })
    const [profileData] = useProfileData()
    const isLoggedIn = isUserLoggedIn(profileData) ? profileData.loginId : undefined
    const [followerProfile, setFollowerProfile] = useState<FollowerProfile | undefined>(initialFollowerProfile)
    const shareProfileContextLink = findContextLinkWithIdFromArray('sellerProfileShare', contextLinkList)

    useEffect(() => {
        async function showFollowUserModalAfterLogin() {
            if (isLoggedIn && storageAvailable('localStorage') && localStorage.getItem(OPEN_FOLLOW_USER_MODAL_STORAGE_KEY) === 'true') {
                localStorage.removeItem(OPEN_FOLLOW_USER_MODAL_STORAGE_KEY)
                if (!followerProfile?.following) {
                    if (searchAgentOptionsLink) {
                        const searchAgentOptions = await getSearchAgentOptions(searchAgentOptionsLink)
                        setSearchAgentUIState({ state: 'showModal', searchAgentOptions: searchAgentOptions })
                    } else {
                        setSearchAgentUIState({ state: 'showModal' })
                    }
                }
            }
        }

        showFollowUserModalAfterLogin()
    }, [followerProfile, isLoggedIn, setSearchAgentUIState, searchAgentOptionsLink])

    return (
        <ProfileContainer display="flex" padding="s" marginBottom={{ tablet: 'm' }}>
            <Box
                backgroundColor="semantic.surface"
                display="flex"
                alignItems="center"
                borderRadius="m"
                flexBasis={{ phone: '100%', tablet: '530px' }}
            >
                <Avatar
                    size={{ phone: 'large', tablet: 'xlarge' }}
                    src={sellerProfile.pictureUrl}
                    margin="m"
                    css={css`
                        border: 2px solid ${(p) => p.theme.colors.semantic.surface.DEFAULT};
                    `}
                />
                <Box display="flex" flexDirection="column" flexBasis="100%" marginRight="m">
                    <Box display="flex" justifyContent="space-between" marginTop="m">
                        <Heading level={1} text={sellerProfile.name} fontSize="l" marginBottom="xs" />
                        <ReportUserProfileButton verticalId={verticalId} userId={sellerProfileLoginId} />
                    </Box>
                    <Box
                        display="flex"
                        flexDirection={{ phone: 'column', tablet: 'row' }}
                        flexGrow="1"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Box display="flex" alignItems="flex-start" flexDirection="column" marginBottom={{ tablet: 'm' }}>
                            <Box fontSize="s" marginBottom="s" display="flex" alignItems="center" color="palette.raccoon">
                                {(sellerProfile.district || sellerProfile.location) && <PinIcon color="palette.elephant" />}
                                {sellerProfile.district}
                                {sellerProfile.location}
                            </Box>
                            <Box fontSize="xs" color="palette.raccoon">
                                <Text as="p">User:in seit {dayjs(sellerProfile.registerDate).format('MM/YYYY')}</Text>
                                {trustSignals?.replyTime && <Text as="p">{trustSignals.replyTime}</Text>}
                                {trustSignals?.presenceText && <Text as="p">{trustSignals.presenceText}</Text>}
                                {shouldShowPayliveryRatings && (
                                    <Box display="flex" alignItems="flex-start">
                                        {trustSignals?.reputation?.feedback?.overallScore && (
                                            <PrivateProfilePayLiveryRatingsBadge
                                                marginTop="xxs"
                                                overallScore={trustSignals.reputation.feedback.overallScore}
                                                marginRight="xs"
                                            />
                                        )}
                                        {trustSignals?.reputation?.feedback?.receivedCount && (
                                            <PrivateProfilePayLiveryRatingsText
                                                showTooltip={false}
                                                ratingsCount={trustSignals.reputation?.feedback.receivedCount}
                                                css={css`
                                                    margin-top: 3px;
                                                `}
                                            />
                                        )}
                                        {!trustSignals?.reputation?.feedback?.overallScore && trustSignals?.payliverySales && (
                                            <Text as="p">
                                                {`${trustSignals.payliverySales} ${
                                                    trustSignals.payliverySales === 1 ? 'Verkauf' : 'Verkäufe'
                                                } mit`}
                                                <PayliveryBadge id="profile-sales-counter" variant="transparent" marginBottom={0} />
                                            </Text>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box display="flex" marginTop="s" alignSelf={{ tablet: 'flex-end' }} marginBottom="m">
                            <Button
                                paddingHorizontal="m"
                                variant="outline"
                                size="small"
                                testId={
                                    followerProfile?.following ? 'seller-profile-unfollow-user-button' : 'seller-profile-follow-user-button'
                                }
                                marginRight="s"
                                marginLeft={{ tablet: 's' }}
                                onClick={async () => {
                                    if (!isLoggedIn) {
                                        if (storageAvailable('localStorage')) {
                                            localStorage.setItem(OPEN_FOLLOW_USER_MODAL_STORAGE_KEY, 'true')
                                        }
                                        redirectToLoginPage()
                                    } else if (followerProfile?.following) {
                                        setUnfollowModalOpen(true)
                                    } else {
                                        if (searchAgentOptionsLink) {
                                            const searchAgentOptions = await getSearchAgentOptions(searchAgentOptionsLink)
                                            setSearchAgentUIState({ state: 'showModal', searchAgentOptions: searchAgentOptions })
                                        } else {
                                            setSearchAgentUIState({ state: 'showModal' })
                                        }
                                    }
                                }}
                            >
                                {followerProfile?.following ? (
                                    <Box display="flex" alignItems="flex-end">
                                        <CheckMarkIcon size="small" color="palette.primary.main" marginRight="xs" />
                                        <Text>Folge ich</Text>
                                    </Box>
                                ) : (
                                    'Folgen'
                                )}
                            </Button>
                            {shareProfileContextLink && (
                                <ShareButton
                                    bodyText="Schau dir diese Anzeigen auf willhaben an."
                                    url={shareProfileContextLink.uri}
                                    taggingData={taggingData}
                                    shareEmailEvent="seller_profile_share_email_click"
                                    shareWhatsAppEvent="seller_profile_share_whatsapp_click"
                                    shareFacebookEvent="seller_profile_share_facebook_click"
                                    shareTwitterEvent="seller_profile_share_twitter_click"
                                    shareLinkEvent="seller_profile_share_link_click"
                                >
                                    {(toggle) => (
                                        <IconButton
                                            size="small"
                                            variant="outline"
                                            Icon={ShareIcon}
                                            name="ShareButton"
                                            aria-label="Profil Teilen"
                                            testId="shareButton"
                                            onClick={toggle}
                                        />
                                    )}
                                </ShareButton>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <ConfirmModal
                fullScreen={{ phone: true, tablet: false }}
                isOpen={unfollowModalOpen}
                onRequestClose={() => setUnfollowModalOpen(false)}
                header="Profil folgen"
                onAbort={() => setUnfollowModalOpen(false)}
                onConfirm={async () => {
                    const unfollowUserLink = findContextLinkWithIdFromArray<ContextLink>('unfollowUser', followerProfile?.contextLinkList)
                    if (unfollowUserLink) {
                        callActionEvent('seller_profile_unfollow_user_click', undefined)
                        await unfollowUserWithLink(unfollowUserLink)
                        setUnfollowModalOpen(false)
                        const newFollowerProfile = await getFollowerProfile(sellerProfileLoginId, verticalId)
                        setFollowerProfile(newFollowerProfile)
                    }
                }}
                confirmButtonText="Nicht mehr folgen"
                abortButtonText="Abbrechen"
            >
                Möchtest du {sellerProfile.name} nicht mehr folgen?
            </ConfirmModal>

            {followerProfile && (
                <CreateSearchAgentModal
                    searchAgentCreateLink={findContextLinkWithIdFromArray('followUser', followerProfile.contextLinkList)}
                    searchAgentOptionsLink={searchAgentOptionsLink}
                    searchAgentUIState={searchAgentUIState}
                    setSearchAgentUIState={async (state) => {
                        callActionEvent('seller_profile_follow_user_click', undefined)
                        setSearchAgentUIState(state)
                        const newFollowerProfile = await getFollowerProfile(sellerProfileLoginId, verticalId)
                        setFollowerProfile(newFollowerProfile)
                    }}
                />
            )}
        </ProfileContainer>
    )
}

export const ProfileContainer = styled(Box)`
    background: url(${wh_pattern});
    background-color: ${(p) => p.theme.colors.palette.primary.main};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    ${(p) => p.theme.media.tablet} {
        border-radius: ${(p) => p.theme.borderRadii.m}px;
    }
`
